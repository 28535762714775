
  .workshop-header {
    background-color: #0E0D16;
    padding: 10px 20px;
    text-decoration: none;
    font-size: 40px;
    font-weight: 500;
    color: #ffffff;
  }
  
 .workshop-form {
    background-color: #0E0D16;
    padding: 10px 20px;
    color: #ffffff;
  }
  
  .workshop-fieldset {
    padding: 0px 0px;
    border: 0;
  }
  

  .workshop-subheader {
    text-align: left;
    font-size: 24px;
    font-weight: 500;
    padding-top: 40px;
    padding-bottom: 15px;
  }
  .workshop-label {
    text-align: left;
    font-size: 14px;
    font-weight: 500;
  }
  .workshop-sublabel {
    text-align: left;
    font-size: 14px;
    font-weight: 400;
  }

  .workshop-commitedtext {
    background-color: #0E0D16;
    padding: 10px 40px;
    text-decoration: none;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    text-align: left;
    padding: 40px;

  }

  .workshop-input-element-noerror {
    border: 1px solid #ccc;
  }

  .workshop-input-element-error {
    border: 2px solid #D83313;
  }

  .workshop-input {
    padding: 0.5em 0.6em;
    display: block;
    box-shadow: inset 0 1px 3px #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 20px;
    width: 100%; 
    max-width: 600px;
  }

  .workshop-checkbox {
    padding: 0.5em 0.6em;
  }

  .workshop-textarea {
    padding: 0.5em 0.6em;
    display: block;
    box-shadow: inset 0 1px 3px #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 20px;
    width: 100%; 
    max-width: 600px;
    height: 150px;
    resize: none; 
  }
  
  .workshop-selected {
    padding: 0.5em 0.6em;
    display: block;
    box-shadow: inset 0 1px 3px #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: #ffffff;
    font-size: 16px;
    line-height: 20px;
    width: 100%; 
    max-width: 600px;
  }

  .workshop-disabled {
    background-color: #e2e2e2;
  }

  .workshop-link {
    color: #df492c;
  }

  .workshop-input-error {
    color: #ff3300;
    font-size: 12px;
    font-weight: 600;
    padding-top: 5px;
    padding-bottom: 15px;

  }
  
  .workshop-button {
    background-color: #D83313;;
    color: #fff;
    font-size: 100%;
    padding: 0.5em 1em;
    border: 1px solid #999;
    text-decoration: none;
    border-radius: 2px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  
  .workshop-button:hover {
    background-color: #df492c;
  }

  body {
    background-color: #0E0D16;
  }


  
  .admin-navigation {
    float: left;
    background-color: #36304a;
    width: 100%;
  }
  
  .admin-navbutton {
    background-color: #36304a;
    color: rgb(255, 255, 255);
    font-size: 100%;
    padding: 0.5em 1em;
    border: 1px solid rgb(59, 59, 59);
  }
  
  .admin-navbutton:hover {
    opacity: 0.5;
  }
  
  .admin-navbutton-selected {
    background-color: #1404ff;
  }
  
  .admin-error {
    color: red;
    margin-left: 1.5em;
    margin-bottom: 0.5em;
    font-size: 16px;
    font-weight: 500;
  }

  .admin-input-error {
    color: red;
    font-size: 12px;
    font-weight: 500;
  }


  .admin-table {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    border: 1px solid black;
    background-color: white;
    margin: 0;
    font-size: 20px;
  }
  
  .admin-table-body {
    display: flex;
    width: 100%;
    color: black;
    flex-wrap: wrap;
  }
  
  .admin-table-header {
    background-color: #36304a;
    color: white;
    width: 100%;
    cursor: pointer;
    position: sticky;
    top: 0;
  }
  
  .admin-row {
    display: inline-flex;
    width: 100%;
    box-sizing: border-box;
  }

  .admin-row-even {
    background-color: #f0f0f0;
  }

  .admin-row-odd {
    background-color: #d0d0d0;
  }
  
  .admin-column {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

  }
  
  .admin-column-id {
    width: 50px;
  }

  .admin-column-options {
    width: 180px;
  }
  
  .admin-column-extrasmall {
    width: 5%;
  }

  .admin-column-small {
    width: 10%;
  }

  .admin-column-medium {
    width: 15%;
  }

  .admin-column-large {
    width: 30%;
  }
    
  .admin-column-extralarge {
    width: 45%;
  }
  
  .admin-icon {
    background: transparent;
    border: none;
    width: 35px;
    height: 35px;
    margin: 0px;
    padding: 0px;
    font-size: 20px;
  }
  
  .admin-iconred {
    color: red;
  }
  
  .admin-icongreen {
    color: green;
  }
  
  .admin-form {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;
    margin: 0;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.5;
  }
  
  .admin-fieldset {
    margin: 20px;
    padding: 0.35em 0 0.75em;
    border: 0;
  }
  
  .admin-label {
    text-align: left;
    margin: 0 1em 0 0;
    width: 100px;
    font-size: 14px;
    font-weight: 500;
  }

  .admin-field {
    margin-bottom: 0.5em;
    box-sizing: border-box;
  }
  
  .admin-input-element-noerror {
    border: 1px solid #ccc;
  }

  .admin-input-element-error {
    border: 1px solid #ff0000;
  }

  .admin-input {
    padding: 0.5em 0.6em;
    display: block;
    box-shadow: inset 0 1px 3px #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    width: 600px;
  }
  

  .admin-textarea {
    padding: 0.5em 0.6em;
    display: block;
    box-shadow: inset 0 1px 3px #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    width: 600px;
    min-height: 100px;
    height: 100px;
    resize: vertical;
  }
  
  .admin-timepicker
  {
    padding: 0.5em 0.6em;
    display: block;
    box-shadow: inset 0 1px 3px #ddd;
    box-sizing: border-box;
    width: 600px;
  }
  
  .admin-selected {
    padding: 0.5em 0.6em;
    display: block;
    box-shadow: inset 0 1px 3px #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: #ffffff;
    width: 600px;
  }
  
  .admin-disabled {
    background-color: #e2e2e2;
  }
  
  .admin-button {
    background-color: #D83313;;
    color: #fff;
    font-size: 100%;
    padding: 0.5em 1em;
    border: 1px solid #999;
    text-decoration: none;
    border-radius: 2px;
    margin-right: 10px;
  }
  
  .admin-button:hover {
    background-color: #df492c;
  }

  
  .login-fieldset {
    display: inline-block;
    padding: 0.35em 0 0.75em;
    border: 0;
  }
  
  .login-label {
    text-align: left;
    display: inline-block;
    width: 100px;
  }

  .login-input {
    padding: 0.5em 0.6em;
    display: inline-block;
    border: 1px solid #ccc;
    box-shadow: inset 0 1px 3px #ddd;
    border-radius: 4px;
    vertical-align: middle;
    box-sizing: border-box;
    width: 200px;
  }

  .login-button {
    background-color: #1404ff;
    color: #fff;
    font-size: 80%;
    padding: 0.25em 1em;
    border: 1px solid #999;
    text-decoration: none;
    border-radius: 2px;
    margin-left: 10px;
  }
  
  .login-button:hover {
    background-color: #04a3ff;
  }
  
  @keyframes blinking-even {
    0% {
      background-color: #f0f0f0;
    }
    50% {
      background-color: #0078e7;
    }
    100% {
      background-color: #f0f0f0;
   }
  }

  @keyframes blinking-odd {
    0% {
      background-color: #d0d0d0;
    }
    50% {
      background-color: #0078e7;
    }
    100% {
      background-color: #d0d0d0;
   }
  }

  .admin-updating-even {
    animation: blinking-even 1s infinite;
  }
  
  .admin-updating-odd {
    animation: blinking-odd 1s infinite;
  }
@media screen {

  .configline {
    background: rgb(75, 75, 100);
    float: left;
    width: 100%;
    height: 35px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
  } 

  .timecolumn {
    background: rgb(85, 80, 110);
    float: left;
    width: 100px;
    min-height: calc(100vh - 30px);
    margin-top: 85px;
  } 

  .timelineheader {
    background: rgb(85, 80, 110);
    border: 1px solid #999;
    height: 50px;
    width: 100px;
    position: fixed;
    top: 35px;
    left: 0;
    z-index: 10;
  }

  .timeline {
    width: 100%;
  }

  .timebackodd {
    background: rgb(85, 80, 110);
  }

  .timebackeven {
    background: rgb(105, 100, 130);
  }


  .spacetable {
    opacity: 0.9;
    background-image:  repeating-linear-gradient(0deg, rgb(100, 100, 117), rgb(100, 100, 117) 150px, rgb(120, 120, 137) 150px, rgb(120, 120, 137));
    background-size: 300px 300px;
    background-position: 0px 85px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    width:  calc(100% - 100px);
    min-height: calc(100vh + 55px);
  }

  .spacecolumn {
    display: inline-block;
    background-color: rgb(85, 80, 110);
    width: 250px;
    border: 1px solid #999;
    vertical-align: top;
    position: relative;
    margin-top: 35px;
  }

  .spacelineheader {
    background: rgb(85, 80, 110);
    border: 1px solid #999;
    height: 50px;
    width: 250px;
    position: fixed;
    top: 35px;
    left: 100px;
    z-index: 5;
  }

  .spaceline {
    box-shadow: 0 -1px 0 #000;
    border-radius: 10px;
    width: 240px;
    position: absolute;
    margin-left: 5px;
    overflow: hidden;
    cursor: pointer;
  }

  .spacelinevisibility
  {
    visibility: visible;
  }

  .spaceline:hover{
    opacity: 0.6;
  }

  .spacelinebig  {
    border: 4px solid rgb(100, 100, 100);
    border-radius: 10px;
    padding: 5px;
    box-shadow: 5px 5px 5px rgb(168, 168, 168);
    z-index: 50;
    position: fixed;
    width: 80%;
    top: 15%;
    left: 10%;
    visibility: hidden;
  }

  .hourheigth {
    height: 150px;
  }

  .texttime {
    font-size: 20px;
    line-height: 32px;
    font-weight: bold;
    text-align: center;
    color: rgb(188, 251, 89);
  }

  .textworkspacenames {
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;  
    text-align: center;
    color: rgb(188, 251, 89);
  }

  .textworkspacenumber {
    font-size: 12px;
    line-height: 16px;
    font-weight: normal;
    text-align: center;
    color: rgb(188, 251, 89);
  }

  .textbig {
    font-size: 20px;
    line-height: 28px;
    font-weight: bold;  
    text-align: center;
  }

  .textmedium {
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;  
    text-align: center;
  }

  .textsmallbold {
    font-size: 11px;
    line-height: 13px;
    font-weight: bold;
    text-align: center;
  }

  .textsmallnormal {
    font-size: 11px;
    line-height: 13px;
    font-weight: normal;
    text-align: center;
  }

  .textwrap {
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-word;
    
  }

  .textright {
    padding-right: 5px;
    float: right;
    text-align: right;
  }

  .textleft {
    padding-left: 5px;
    float: left;
    text-align: left;
  }

  .textspace {
    margin-bottom: 10px;
  }

  .textspacebig {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .textclose  {
    float: right;
    font-size: 45px;
    font-weight: 600;
    text-align: right;
    margin-top: -10px;
    cursor: pointer;
  }

  .level_1 {
    background: rgb(188, 217, 240);
  }

  .level_2 {
    background: rgb(185, 255, 220);
  }

  .level_3 {
    background: rgb(255, 255, 220);
  }
  
  .level_4 {
    background: rgb(255, 185, 185);
  }

  .level_s {
    background: rgb(255, 185, 255);
  }


  .advancer {
    appearance: none;
    background: transparent;
    padding: 0;
    border: 0;
    position: fixed;
    top: 35px;
    bottom: 0;
    z-index: 100;
    opacity: 1;
    transition: opacity 0.3s;
  }

  .advancer:focus {
    outline: 0;
  }

  .advancer:hover {
    cursor: pointer;
  }

  .advancerleft {
    left: 5px;
  }

  .advancerright {
    right: 10px;
  }

  .advancericon {
    width: 20px;
    height: 44px;
    fill: #bbb;
  }


  .datenav {
    float: left;
    background: transparent;
    padding: 4px;
    border: 0;
  }

  .datenavleft {
    margin-left: 10px;
  }

  .dateelement {
    float: left;
    width: 80px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .datenav:focus {
    outline: 0;
  }

  .datenav:hover {
    cursor: pointer;
  }

  .datenavicon {
    width: 10px;
    height: 22px;
    fill: rgb(143, 126, 126);
  }
}

@media print {

  .configline {
    float: left;
    width: 100%;
    height: 20px;
  } 

  .timecolumn {
    float: left;
    width: 45px;
  } 

  .timelineheader {
    
    border: 1px solid #000000;
    height: 35px;
    width: 100%;
  }

  .timeline {
    border: 1px solid #000000;
    width: 100%;
  }

  .spacetable {
    width: 100%;
  }

  .spacecolumn {
    display: inline-block;
    width: 95px;
    border: 1px solid #000000;
    position: relative;
  }

  .spacelineheader {

    border: 1px solid #000000;
    height: 35px;
    width: 95px;
  }

  .spaceline {
    margin: 1px;
    width: 92px;
    position: absolute;
    border: 1px solid #000000;
    border-radius: 5px;

  }

  .spacelinevisibility
  {
    visibility: hidden;
  }

  .hourheigth {
    height: 80px;
  }

  .texttime {
    font-size: 10px;
    line-height: 11px;
    font-weight: bold;
    text-align: center;
  }

  .textworkspacenames {
    font-size: 12px;
    line-height: 14px;
    font-weight: bold;  
    text-align: center;
  }

  .textworkspacenumber {
    font-size: 8px;
    line-height: 10px;
    font-weight: normal;
    text-align: center;
  }

  .textbig {
    font-size: 11px;
    line-height: 12px;
    font-weight: bold;  
    text-align: left;
  }

  .textmedium {
    font-size: 8px;
    line-height: 9px;
    font-weight: bold;  
    text-align: left;
    padding-left: 2px;
  }

  .textsmallbold {
    font-size: 8px;
    line-height: 10px;
    font-weight: bold;
    text-align: left;
  }

  .textsmallnormal {
    font-size: 8px;
    line-height: 10px;
    font-weight: normal;
    text-align: left;
  }

  .textwrap {
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-word;
    
  }
  .textright {
    padding-left: 2px;
    float: left;
    text-align: left;
  }

  .textleft {
    padding-left: 2px;
    float: left;
    text-align: left;
  }

  .textspace {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .textspacebig {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .textclose  {
    float: right;
    font-size: 45px;
    font-weight: 600;
    text-align: right;
    margin-top: -10px;
    cursor: pointer;
  }

  .advancer {
    appearance: none;
    background: transparent;
    padding: 0;
    border: 0;
    position: fixed;
    top: 35px;
    bottom: 0;
    z-index: 100;
    opacity: 1;
    transition: opacity 0.3s;
    visibility: hidden;
  }

  .advancer:focus {
    outline: 0;
  }

  .advancer:hover {
    cursor: pointer;
  }

  .advancerleft {
    left: 5px;
  }

  .advancerright {
    right: 10px;
  }

  .advancericon {
    width: 20px;
    height: 44px;
    fill: #bbb;
  }


  .datenav {
    float: left;
    background: transparent;
    padding: 4px;
    border: 0;
  }

  .datenavleft {
    margin-left: 10px;
  }

  .dateelement {
    float: left;
    width: 80px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .datenav:focus {
    outline: 0;
  }

  .datenav:hover {
    cursor: pointer;
  }

  .datenavicon {
    width: 10px;
    height: 22px;
    fill: rgb(143, 126, 126);
    visibility: hidden;
  }
}



